import React, {useContext, useState, useRef, useEffect} from 'react';
import {Nav, Navbar, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {LanguageContext} from './LanguageContext';
import logo from './001.png';
import KG from './kyr.png';
import RU from './rus.png';
const Header = () => {
    const {setLanguage, language} = useContext(LanguageContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);

    const changeLanguageToRu = () => {
        setLanguage('ru');
        localStorage.setItem('language', 'ru');
    };

    const changeLanguageToKg = () => {
        setLanguage('kg');
        localStorage.setItem('language', 'kg');
    };
    const messages_ru = {
        home: "На Главную",
        about: "Поддержка",
        contacts: "Контакты",
        terminal: "Карта терминалов",
        address: "Список терминалов",
        coop: "Сотрудничество"
    };

    const messages_kg = {
        home: "Башкы бет",
        about: "Боюнча",
        contacts: "Байланыш",
        terminal: "Терминал картасы",
        address: "Терминалдар тизмеси",
        coop: "Ишкердик"
    };

    const getMessage = (key) => {
        const messages = language === 'kg' ? messages_kg : messages_ru;
        return messages[key];
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutsideNavbar = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {

                setMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutsideNavbar);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideNavbar);
        };
    }, []);

    return (

            <Navbar fixed="top" collapseOnSelect expand="md" bg="dark" variant="dark" expanded={menuOpen}
                    ref={navbarRef}>
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <img src={logo} height="15" width="100" className="d-inline-block align-center" alt="Logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setMenuOpen(!menuOpen)}/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="m-lg-auto" onClick={closeMenu}>
                            <Nav.Link as={Link} to="/">
                                {getMessage('home')}
                            </Nav.Link>
                            <Nav.Link as={Link} to="/about">
                                {getMessage('about')}
                            </Nav.Link>
                            <Nav.Link as={Link} to="/contacts">
                                {getMessage('contacts')}
                            </Nav.Link>
                            <Nav.Link as={Link} to="/terminal">
                                {getMessage('terminal')}
                            </Nav.Link>
                            <Nav.Link as={Link} to="/address">
                                {getMessage('address')}
                            </Nav.Link>
                            <Nav.Link as={Link} to="/coop">
                                {getMessage('coop')}
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div className="ml-auto">
                        <img src={KG} alt="Kyrgyz" height="30" className="d-inline-block align-top"
                             onClick={changeLanguageToKg}/>
                        <img src={RU} alt="Russian" height="30" className="d-inline-block align-top"
                             onClick={changeLanguageToRu}/>

                    </div>
                </Container>
            </Navbar>

    );
};

export default Header;