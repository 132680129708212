import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { LanguageContext } from '../components/LanguageContext';


const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "black",
    padding: "0",
};

const cardStyle = {
    backgroundColor: "black",
    color: "white",
    maxWidth: "350px",
    border: "2px solid #17a2b8",
};

function Contacts() {
    const { language } = useContext(LanguageContext);
    const [cardVisible, setCardVisible] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [language]);

    const fetchData = async () => {
        try {
            const response = await fetch('Contacts.json');
            const jsonData = await response.json();
            setData(jsonData[language]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <Container fluid style={containerStyle}>
            <div className={`text-center card-animation ${cardVisible ? 'card-visible' : ''}`}>
                <Card border="#17a2b8" style={cardStyle}>
                    <Card.Body>
                        <Card.Text style={{ textAlign: 'center' }}>
                            {data.map((item, index) => (
                                <React.Fragment key={index}>
                                    {Array.isArray(item.text) ? (
                                        item.text.map((subItem, subIndex) =>
                                            typeof subItem === 'object' ? (
                                                <a key={subIndex} href={subItem.href} style={{ display: 'block', marginRight: '-15px' }}>
                                                    {subItem.text}
                                                </a>
                                            ) : (
                                                <span key={subIndex} style={{ display: 'block', marginRight: '-15px' }}>{subItem}</span>
                                            )
                                        )
                                    ) : (
                                        <span key={index} style={{ display: 'block', marginRight: '-15px' }}>{item.text}</span>
                                    )}
                                </React.Fragment>
                            ))}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    );
}

export default Contacts;