import React, { useEffect, useState} from 'react';
import './style.css'

const Address = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('address.json');
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const toggleBlock = (index) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    }

    return (
        <div className="address-container">
            {data.map((region, index) => (
                <div className="block" key={index}>
                    <button className="btn btn-dark btn-lg btn-block" onClick={() => toggleBlock(index)}>
                        {region.region}
                    </button>
                    {activeIndex === index && (
                        <div className="block-content">
                            {region.address.map((address, i) => (
                                <p key={i}>{address}</p>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Address;