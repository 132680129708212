import React, { useState, useContext, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { LanguageContext } from '../components/LanguageContext';
import './about.css';

const containerStyle = {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'black',
};

const textContainerStyle = {
    color: 'white',
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    marginTop: '20vh',
};

function AboutCompany() {
    const { language } = useContext(LanguageContext);
    const [data, setData] = useState([]);
    const [responses, setResponses] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        fetchData();
    }, [language]);

    const fetchData = async () => {
        try {
            const response = await fetch('About.json');
            const jsonData = await response.json();
            setData(language === 'ru' ? jsonData.ru : jsonData.kg);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleClick = (index) => {
        setResponses(prevState => {
            const newResponses = [...prevState];
            newResponses[index] = !newResponses[index];
            for (let i = 0; i < newResponses.length; i++) {
                if (i !== index) {
                    newResponses[i] = false;
                }
            }
            return newResponses;
        });
    };

    return (
        <Container fluid style={{...containerStyle, opacity: isVisible ? 1 : 0}} className="slide-in-container">
            <div style={textContainerStyle}>
                <h2>{language === 'ru' ? 'Ответы на часто задаваемые вопросы' : 'Кыйынчылыкка уушалган суроолорга жооптар'}</h2>
                <div>
                    {data.map((item, index) => (
                        <Card
                            bg="black"
                            key={index}
                            text="white"
                            style={{
                                width: "100%",
                                marginBottom: "15px",
                                cursor: "pointer",
                                border: "2px solid #17a2b8"
                            }}
                            onClick={() => handleClick(index)}
                            className={responses[index] ? "card-animation visible" : "card-animation"}
                        >
                            <Card.Body>
                                <Card.Title>{item.question}</Card.Title>
                                {responses[index] && <Card.Text>{item.answer}</Card.Text>}
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </div>
        </Container>
    );
}

export default AboutCompany;