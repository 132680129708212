import React, { useState, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import unipay from "../assets/unipay.png";
import "../components/style.css";
import { LanguageContext } from "../components/LanguageContext";

const bodyStyle = {
    overflow: "hidden",
    margin: 0,
    padding: 0,
    height: "100vh",
};

const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "black",
    padding: "0",
};

const cardContainerStyle = {
    position: "relative",
    width: "100%",
    maxWidth: "350px",
    marginTop: "65px",
    marginBottom: "50px",
};

const cardStyle = {
    backgroundColor: "black",
    color: "white",
    border: "2px solid #17a2b8",
    padding: "20px",
    minHeight: "fit-content",
};

function Home() {
    const [cardVisible] = useState(false);
    const { language } = useContext(LanguageContext);
    const [mainImageText, setMainImageText] = useState({ paragraphs: [] });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("MainImage.json");
                const jsonData = await response.json();
                setMainImageText(jsonData[language]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [language]);

    return (
        <div style={bodyStyle}>
            <Container fluid style={containerStyle}>
                <div style={cardContainerStyle}>
                    <Card
                        bg="black"
                        text="white"
                        className={`text-center card-animation ${
                            cardVisible ? "card-visible" : ""
                        }`}
                        style={cardStyle}
                    >
                        <Card.Body>
                            <img className="d-block main-image" src={unipay} alt="mainimage" />
                            <Card.Text>
                                {mainImageText.paragraphs.map((paragraph, index) => (
                                    <span key={index}>{paragraph}<br /></span>
                                ))}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </div>
    );
}

export default Home;