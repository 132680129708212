import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Terminal extends Component {
    render() {
        return (
            <Container fluid className="p-0">
                <Row>
                    <Col>
                        <div className="map-container">
                            <iframe
                                title="Google Map"
                                className="w-100"
                                style={{
                                    height: 'calc(100vh - 56px)',
                                    marginTop: '56px'
                                }}
                                src="https://www.google.com/maps/d/embed?mid=1ztfA2kplaVYCpVUvpEvllCiP_7g57ik&ll=42.874621,74.600425&z=13&ehbc=2E312F"
                                width="640"
                                height="480"
                                allowFullScreen
                                loading="lazy"
                            ></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Terminal;