import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/header";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Terminal from "./pages/Terminal";
import Address from "./pages/Address";
import Coop from "./pages/Coop";

import { LanguageProvider } from './components/LanguageContext'; // Импортируем провайдер языка

function App() {
    return (
        <Router>
            <LanguageProvider>
                <div>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/terminal" element={<Terminal />} />
                        <Route path="/address" element={<Address />} />
                        <Route path="/coop" element={<Coop />} />
                    </Routes>
                </div>
            </LanguageProvider>
        </Router>
    );
}

export default App;